<template>
  <v-navigation-drawer 
    :value="toggleSidebar" 
    color="white" 
    app width="220" 
    @input="inputEventSideBar"
    >
    <v-list-item>
      <v-list-item-title class="text-capitalize" align="center" justify-center>
        <v-img 
          contain
          height="150"
          width="150"
          src="@/assets/img/Logo-506.png">
          </v-img>
      </v-list-item-title>
    </v-list-item>

    <v-list dense rounded>
      <v-list-item-group color="primary-second" v-model="selectedItem">
        <!-- Dashboard -->
        <v-list-item :to="MenuCompuesto.index1.route">
          <v-list-item-icon class="mr-4">
            <v-icon v-text="MenuCompuesto.index1.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="MenuCompuesto.index1.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Operaciones -->
        <v-list-group          
          append-icon=""
          color="#0D47A1"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-4">
              <v-icon v-text="MenuCompuesto.index2.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="MenuCompuesto.index2.text"></v-list-item-title>
            </v-list-item-content>

          </template>
          <v-list-item :to="MenuCompuesto.index2.opciones.opcion1.route">
            <v-list-item-title
               class="ml-10" 
              v-text="MenuCompuesto.index2.opciones.opcion1.subMenuText"></v-list-item-title>
          </v-list-item>

          <v-list-group
            no-action 
            sub-group
            prepend-icon=""
            >
            
            <template v-slot:activator>
              <v-list-item-title 
               class="ml-8"
              v-text=" MenuCompuesto.index2.opciones.opcion2.subMenuText"></v-list-item-title>
            </template>
            
            <template v-slot:appendIcon>
              <v-icon >mdi-menu-down</v-icon>
            </template>
            <v-list-item
              v-for="subItem in MenuCompuesto.index2.opciones.opcion2.subMenu"
              :key="subItem.index"
              link
              :to="subItem.route"
            >
              <v-list-item-title v-text="subItem.text"></v-list-item-title>

            </v-list-item>
          </v-list-group>

          <v-list-group
            no-action 
            sub-group
            prepend-icon=""
            >
            
            <template v-slot:activator>
              <v-list-item-title 
                class="ml-8"
                v-text=" MenuCompuesto.index2.opciones.opcion3.subMenuText"></v-list-item-title>
            </template>
            
            <template v-slot:appendIcon>
              <v-icon >mdi-menu-down</v-icon>
            </template>
            <v-list-item
              v-for="subItem in MenuCompuesto.index2.opciones.opcion3.subMenu"
              :key="subItem.index"
              :to="subItem.route"
            >
              <v-list-item-title v-text="subItem.text"></v-list-item-title>

            </v-list-item>
          </v-list-group>

          <!-- Configuraciones -->
          <v-list-group
            no-action
            sub-group
            prepend-icon=""
            >

            <template v-slot:activator>
              <v-list-item-title 
                class="ml-8"
                v-text=" MenuCompuesto.index2.opciones.opcion4.subMenuText"></v-list-item-title>
            </template>

            <template v-slot:appendIcon>
              <v-icon >mdi-menu-down</v-icon>
            </template>
            <v-list-item
              v-for="subItem in MenuCompuesto.index2.opciones.opcion4.subMenu"
              :key="subItem.index"
              :to="subItem.route"
            >
              <v-list-item-title v-text="subItem.text"></v-list-item-title>

            </v-list-item>
          </v-list-group>


        </v-list-group>

        <!-- Asignaciones -->
        <v-list-group
          append-icon=""
          color="#0D47A1"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-4">
              <v-icon v-text="MenuCompuesto.index3.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="MenuCompuesto.index3.text"></v-list-item-title>
            </v-list-item-content>            
          </template>

          <v-list-item :to="MenuCompuesto.index3.opciones.opcion1.route">
            <v-list-item-title
               class="ml-10" 
              v-text="MenuCompuesto.index3.opciones.opcion1.subMenuText"></v-list-item-title>
          </v-list-item>

          <v-list-item :to="MenuCompuesto.index3.opciones.opcion2.route">
            <v-list-item-title
               class="ml-10" v-text="MenuCompuesto.index3.opciones.opcion2.subMenuText">
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="MenuCompuesto.index3.opciones.opcion3.route">
            <v-list-item-title
               class="ml-10" 
              v-text="MenuCompuesto.index3.opciones.opcion3.subMenuText"></v-list-item-title>
          </v-list-item>

          <v-list-item :to="MenuCompuesto.index3.opciones.opcion4.route">
            <v-list-item-title
               class="ml-10" 
              v-text="MenuCompuesto.index3.opciones.opcion4.subMenuText"></v-list-item-title>
          </v-list-item>        
          
        </v-list-group>

        <!-- Reportes -->
        <v-list-item >
          <v-list-item-icon class="mr-4">
            <v-icon v-text="MenuCompuesto.index4.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title  v-text="MenuCompuesto.index4.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Configuracion -->
        <v-list-group
          append-icon=""
          color="#0D47A1"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-4">
              <v-icon v-text="MenuCompuesto.index5.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="MenuCompuesto.index5.text"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item :to="MenuCompuesto.index5.opciones.opcion1.route">
            <v-list-item-title
               class="ml-10" 
              v-text="MenuCompuesto.index5.opciones.opcion1.subMenuText"></v-list-item-title>
          </v-list-item>

          <v-list-item :to="MenuCompuesto.index5.opciones.opcion2.route">
            <v-list-item-title
               class="ml-10" 
              v-text="MenuCompuesto.index5.opciones.opcion2.subMenuText"></v-list-item-title>
          </v-list-item>
          
        </v-list-group>

        <!-- Interface -->
        <v-list-group
          append-icon=""
          color="#0D47A1"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-4">
              <v-icon v-text="MenuCompuesto.index6.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="MenuCompuesto.index6.text"></v-list-item-title>
            </v-list-item-content>            
          </template>

          <v-list-item>
            <v-list-item-title
               class="ml-10" 
              v-text="MenuCompuesto.index6.opciones.opcion1.subMenuText"></v-list-item-title>
          </v-list-item>

           <v-list-item>
            <v-list-item-title
               class="ml-10" 
              v-text="MenuCompuesto.index6.opciones.opcion2.subMenuText"></v-list-item-title>
          </v-list-item>

        </v-list-group>


    </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block rounded color="primary white--text" @click="logout">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      rutaActual: "",
      drawer: false,
      selectedItem: 0,
      Menu: {
        option1: {
          icon: "mdi-home",
          text: "Dashboard",
          route: "/Dashboard",
        }
      },
      
      MenuCompuesto: {
        index1: {
          icon: "mdi-home",
          text: "Dashboard",
          route: "/Home",
        },
        index2: {
          icon: "mdi-wallet-travel",
          text: "Operaciones",
          opciones: {
            opcion1: {
              subMenuText: 'Pagos',
              route: '/Receipt'
            },
            opcion2: {
              subMenuText: 'Ventas',
              subMenu:[
                {
                  text: "Reservas",
                  route: "/Reservas",
                },
                {
                  text: "Invoice",
                  route: "/Invoice",
                },
                {
                  text: "Products",
                  route: "/Products",
                },
                {
                  text: "Locations",
                  route: "/Locations",
                },
                {
                  text: "Customers",
                  route: "/Customers",
                }
              ]
            },
            opcion3: {
              subMenuText: 'Historicos',
              subMenu:[
                // {
                //   text: "Reservas",
                //   route: "/Reservas",
                // },
                {
                  text: "old Invoices",
                  route: "/HInvoice",
                },
                // {
                //   text: "Products",
                //   route: "/Products",
                // },             
                // {
                //   text: "Customers",
                //   route: "/Customers",
                // }
              ]
            },
            opcion4 : {
              subMenuText: 'Configuracion',
              subMenu:[
                {
                  text: "Reservas",
                  route: "/ReservasParams",
                },
                {
                  text: "Tipo de Cambios",
                  route: "/ExchangeRate",
                },
                {
                  text: "Productos",
                  route: "/ProductParams",
                },
                {
                  text: "Customers",
                  route: "/CustomerParams",
                },
                {
                  text: "Adicionales",
                  route: "/Adicionales",
                }
              ]
            }
         }  
        },
        index3: {
          icon: "mdi-train-car",
          text: "Asignaciones",
          opciones: {
            opcion1: {
              subMenuText: "Asignar Tour",
              route: "/Assignment",
            },
            opcion2: {
              subMenuText: "Proveedores",
              route: "/Suppliers",
            },
            opcion3: {
              subMenuText: "Vehiculos",
              route: "/Vehicles",
            },
            opcion4: {
              subMenuText: "Comisiones",
              route: "/Commissions",
            }     
          }
        },
        index4: {
          icon: "mdi-chart-bar",
          text: "Reportes",
          route: "/Reportes",
        },
        index5: {
          icon: "mdi-cog-outline",
          text: "Configuracion",
          route: "/Configuracion",
          opciones:{
            opcion1: {
              subMenuText: "Permisos",
              route: "/Permissions",
            },
            opcion2: {
              subMenuText: "Usuarios",
              route: "/Users",
            }           
          }
        },
        index6: {
          icon: "mdi-connection",
          text: "Interface",
          opciones:{
            opcion1: {
              subMenuText: "Email-Ops",
              route: "/Email-Ops",
            },
            opcion2: {
              subMenuText: "Calendar-Ops",
              route: "/Calendar-Ops",
            },            
          }
        },
      },
      method: {}
    }
  },
  methods: {
    inputEventSideBar(item){
     if (item){
       this.$store.state.toggleSidebar = item
     } else {
       this.$store.state.toggleSidebar = item
     }
    },
    logout() {
      this.$store
        .dispatch("logoutUser")
        .then(() => {
          this.$router.push({ name: "Login" })
        })
        .catch((err) => {
          alert(err)
        })
    },
  },
  computed: {
    toggleSidebar(){
      return this.$store.state.toggleSidebar
    }
    
  },
}
</script>

<style scoped>
div >>> .v-list a {
  text-decoration: none;
}
.v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item{
  padding-left: 72px !important;
}
.v-list-item__title, .v-list-item__subtitle{
  overflow: unset;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child {
  margin-right: 0px !important;
}
</style>
