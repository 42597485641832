<template>
  <div>
    <side-bar></side-bar>

    <v-app-bar app color="primary" height="55">
      <v-app-bar-nav-icon
        @click="activaToggle"
        color="white"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="white--text">Modulo - {{$route.name}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-avatar>
        <v-icon>mdi-account</v-icon>
      </v-avatar>

      <v-toolbar-title class="white--text mr-5 subtitle-1" v-if="breakStatus != 'xs'"
        >{{$store.state.first_name && $store.state.last_name ? `${$store.state.first_name} ${$store.state.last_name}` : $store.state.name}}
      </v-toolbar-title>

    </v-app-bar>

    <v-main class="pa-0 ma-0">
      <ZoomCenterTransition :duration="200" mode="out-in">
        <MainDashboard v-if="$route.name == 'Home'">

        </MainDashboard>
        <router-view v-if="$route.name != 'Home'"></router-view>
      </ZoomCenterTransition>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue"
import MainDashboard from "./dashboards/MainDashboard.vue"
import { ZoomCenterTransition } from 'vue2-transitions'

export default {
  name: "Home",
  components: {
    SideBar,
    ZoomCenterTransition,
    MainDashboard
  },
  data: () => ({}),
  methods: {
    logout() {
      this.$store
        .dispatch("logoutUser")
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch((err) => {});
    },
    activaToggle() {
      console.log('active el sidebar')
      this.$store.dispatch("actActiveSidebar", !this.$store.state.toggleSidebar);
    },
  },
  computed: {
    breakStatus() {
      const bs = this.$vuetify.breakpoint.name;
      return bs;
    },
    
  }
};
</script>

<style scoped></style>

