<template>
  <v-card class="border-radius-xl py-2 card-shadow rounded-xl" >
    <v-row dense no-gutters class="px-4">
      <v-col cols="12" md="12">
        <p class="my-0 d-flex justify-space-between">
          <span class="text-capitalize text-sm-body-2 mb-1 font-weight-bold text-grey-body--text">
            {{cardTitle}}
          </span>
          <span class="mr-2">{{ prependSing}}{{$convertToMoney(CardTotalValue)}}</span>
        </p>
      </v-col>
      <!-- <v-col cols="12" md="12" class="text-end">
        <v-menu
          transition="slide-x-transition"
          bottom
          right
          offset-x
          offset-y
          content-class="card-shadow"
          style="min-width: 20px;"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-capitalize"
              color="text-grey-body"
              dark
              v-bind="attrs"
              v-on="on"
              small
              elevation="0"
              text
              plain
              depressed
              retain-focus-on-click
              :ripple="false"
            >
              <span class="text-xs text-secondary font-weight-light text-grey-body--text">{{menuSelectedOption.title}}</span>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              class="py-0"
              v-for="(item, i) in items"
              :key="i"
              @click="selectedOption(item)"
              style="min-height: 20px;"
            >
              <v-list-item-content  class="py-0">
                <span class="text-xs text-caption font-weight-light text-grey-body--text py-0">{{ item.title }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col> -->
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'CardTotals',
  props: {
    items: {
      type: Array,
      required: true
    },
    cardTitle: {
      type: String,
      required: true
    },
    prependSing: {
      type: String,
      required: false
    },
    CardTotalValue:{
      type: Number,
      required: true
    },
    BudgetFields:{
      type:Boolean,
      require: false,
      default: false
    },
    BudgetData:{
      type:Object,
      require: false,
      default: () => ({
        percent: 0,
        meta: 0,
        dif: 0

      })
    },
      botomLineText:{
        //'text-grey-body' como color primario
        type: Object,
        default: () => ({
          text: {
            color1: "success--text",
            line1: "+56%",
            color2: "",
            line2: "mas que el dia anterior"
          }
        })
      }
  },
  data() {
    return {
      menuSelectedOption: {title: 'Hoy'}
    }
  },
  methods: {
    selectedOption(item){
      this.menuSelectedOption = item
    }
  }
}
</script>

<style scoped>
.budget-size{
  font-size: 0.60rem !important;
}

</style>
