<template>
  <!-- <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  /> -->
  <v-card class="border-radius-xl py-4 px-4 card-shadow rounded-xl" >
    <div class="d-flex justify-content-between">
      <p class="text-capitalize text-sm-body-2 font-weight-bold text-grey-body--text mb-0">{{pieTitle}}</p>
    </div>
    <v-row class="mt-1 py-2">
      <v-col cols="12" md="7" class="text-start pl-0 pt-0 pr-0">
        <div class="chart-area">
          <Pie
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
          />
        </div>
      </v-col>

      <v-col cols="12" md="5" class="my-auto px-1">
        <div v-for="item, index in chartData.labels" :key="index">
          <v-badge
            bordered
            dot
            inline
            left
            :color="allColors[index]"
          >
            <span class="text-caption font-weight-light text-grey-body--text" >{{item}}</span>
          </v-badge>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import {axiosN506, axiosB} from '@/api/axiosBd'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
const pieColors = ['#29B6F6', '#4DD0E1', '#81C784', '#90A4AE','#F06292', '#9575CD', '#3F51B5']
const pieColors2 = ['#29B6F6', '#4DD0E1', '#81C784', '#90A4AE','#F06292', '#9575CD', '#3F51B5']

export default {
  name: 'DoughnutChart',
  components: {
    Pie
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 135
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    pieTitle: {
      default: '',
      type: String
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      allColors: pieColors,
      // chartData: {
      //   labels: [],
      //   datasets: []
      // },
      // chartOptions: {
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   plugins: {
      //     legend: {
      //         display: false,
      //     }
      //   }
      // },
    }
  },
  methods: {
    // async getDataOnePy(obj){
    //   let dataResult = null
    //   try {
    //     await this.$store.dispatch('verifyTokenPy')
    //     const response = await axiosB({method: obj.method,url: obj.url,headers: { Authorization: `Bearer ${this.$store.state.accessTokenPy}` },data: obj.body })
    //     console.log(response)
        
    //     let data = []
    //     for (let item of response.data.subfamily_sales){
    //       this.chartData.labels.push(item.subfamilia)
    //       data.push(item.monto)
    //     }

    //     this.chartData.datasets.push(
    //        {
    //         backgroundColor: pieColors,
    //         data: data
    //       }
    //     )

    //   } catch (error) {
    //     if (error.response) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       console.log(error.response.data);
    //       console.log(error.response.status);
    //       console.log(error.response.headers);
    //     } else if (error.request) {
    //       // The request was made but no response was received
    //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //       // http.ClientRequest in node.js
    //       console.log(error.request);
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       console.log('Error', error.message);
    //     }
    //     console.log(error.config);
    //   }
      
    //   return dataResult
    // }
  },
  created(){
  //   let ventas = {
  //     url: `extractor/api/506transfers/dashboardQueryView`,
  //     method: 'GET',
  //     body: {},
  //   }
  //  this.getDataOnePy(ventas)
  }
}
</script>
