<template>
  <div>
    <v-row align="center" dense>
      <v-col cols="12" md="2" sm="6">
        <CardSimple
          class="mb-2"
          :items="items"
          cardTitle="Dia"
          :CardTotalValue="dia"
          prependSing="$ "
        ></CardSimple>
        <CardSimple
          :items="items"
          cardTitle="Pax año"
          :CardTotalValue="acumuladoPax"
        ></CardSimple>
      </v-col>
      <v-col cols="12" md="2" sm="6">
        <card-totals
          :items="items"
          cardTitle="Mes hasta hoy"
          :CardTotalValue="mesHastaLaFecha"
          prependSing="$"
          :BudgetFields="true"
          :BudgetData="budgetMesHastaHoy"
        ></card-totals>
      </v-col>
      <v-col cols="12" md="2" sm="6">
        <card-totals
          :items="items"
          cardTitle="Mes Completo"
          :CardTotalValue="mes"
          prependSing="$"
          :BudgetFields="true"
          :BudgetData="budgetMescompleto"
        ></card-totals>
      </v-col>
      <v-col cols="12" md="2" sm="6">
        <card-totals
          :items="items"
          cardTitle="Acumulado hoy"
          :CardTotalValue="acumuladoHoy"
          prependSing="$"
          :BudgetFields="true"
          :BudgetData="budgetAcumuladoHoy"
        ></card-totals>
      </v-col>
      <v-col cols="12" md="2" sm="6">
        <card-totals
          :items="items"
          cardTitle="Acumulado año"
          :CardTotalValue="acumulado"
          prependSing="$"
          :BudgetFields="true"
          :BudgetData="budgetAcumuladoYear"
        ></card-totals>
      </v-col>
      <v-col cols="12" md="2">
        <v-card rounded="xl" class="mt-2 py-1 px-4" elevation="2">
          <p class="pa-0 ma-0 text-caption">
            {{ `Avg. Pax dia: $${$convertToMoney(promPaxDia, 2)}` }}
          </p>
          <span class="text-caption"
            ><span text-color="#00000099">Avg. Pax mes:</span
            >{{ ` $${$convertToMoney(promPaxMes, 2)}` }}</span
          >
        </v-card>
        <div class="d-flex justify-end">
          <v-btn icon color="primary" @click="openDescargar">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row dense>
      <!--  -->
      <v-col cols="12" md="3">
        <Pie
          pieTitle="Segmentos"
          :chartData="chartDataPie"
          :chartOptions="chartOptionsPie"
        ></Pie>
      </v-col>
      <v-col cols="12" md="5">
        <BarChart
          :chartData="chartDataBars"
          :chartOptions="chartOptionsBars"
        ></BarChart>
      </v-col>
      <v-col cols="12" md="4">
        <BarChart
          :chartData="chartDataBarsYearbyMonth"
          :chartOptions="chartOptionsBars"
        ></BarChart>
      </v-col>

      <!-- tabla de pax -->
      <v-col cols="12" md="2">
        <pax-table :dataRecords="itemsPaxTable"></pax-table>
      </v-col>

      <!-- tabla de rubros de ingresos -->
      <v-col cols="12" md="10">
        <table-middle :dataRecords="itemsDataTable"></table-middle>
        <v-card rounded="xl" elevation="2" class="mt-2 pa-2 pl-3">
          <v-row dense>
            <v-col cols="12" md="6">
              <p class="text-caption my-0">
                <span class="mr-1 font-weight-medium">Avg. Venta x dia:</span>
                <span class="mr-3">{{ `$${$convertToMoney(promVtaDia)}` }}</span>
              </p>
              <p class="text-caption my-0">
                <span class="">Meta:</span>
                <span :class="promVtaDia >= 0 ? 'success--text' : 'error--text'" class="mr-2">{{ `$${$convertToMoney(budgetPromVtaDia)}` }}</span>
                <span :class="promVtaDia >= 0 ? 'success--text font-weight-medium' : 'error--text'">{{ `${promVtaDia > 0 ? '+': promVtaDia == 0 ? '' :'-'}${budgetPromVtaDiaPercent ? `${budgetPromVtaDiaPercent}%` : ''}`}}</span>
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <p class="text-caption my-0">
                <span class="mr-1 font-weight-medium">Avg. Venta x Mes:</span>
                <span class="mr-3">{{ `$${$convertToMoney(promVtaMes)}` }}</span>
              </p>
              <p class="text-caption my-0">
                <span class="mr-1 font-weight-medium">Meta:</span>
                <span :class="promVtaMes >= 0 ? 'success--text' : 'error--text'" class="mr-2">{{ `$${$convertToMoney(budgetPromVtaMes)}` }}</span>
                <span :class="promVtaMes >= 0 ? 'success--text font-weight-medium' : 'error--text'">{{ `${promVtaMes > 0 ? '+': promVtaMes == 0 ? '' :'-'}${budgetPromVtaMesPercent ? `${budgetPromVtaMesPercent}%` : ''}`}}</span>
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!--  -->
      <!-- <v-col cols="12" md="4">
          <SalesCardSummary></SalesCardSummary>
      </v-col> -->
      <!--  -->
      <!-- <v-col cols="12" md="4">
          <SalesCardSummary></SalesCardSummary>
      </v-col> -->
    </v-row>

    <!-- cuadro de dialogo -->
    <v-row>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="dialogDescargar"
      >
        <template v-slot:default="dialog">
          <v-card :loading="loading">
            <v-toolbar color="primary" dark
              >Favor seleccione el mes a descargar</v-toolbar
            >
            <v-card-text :loading="loading">
              <div class="text-h2 pa-12">
                <v-chip-group active-class="primary--text" column>
                  <v-chip v-for="mes in meses" :key="Object.keys(mes)[0]">
                    <div @click="descargarExcel(mes)">
                      {{ Object.keys(mes)[0] }}
                    </div>
                  </v-chip>
                </v-chip-group>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import BarChart from "@/components/dashboard/Bars.vue"
import TableMiddle from "@/components/dashboard/TableMiddle.vue"
import Pie from "@/components/dashboard/Pie.vue"
import CardTotals from "@/components/dashboard/CardTotals.vue"
import CardSimple from "@/components/dashboard/CardSimple.vue"
import PaxTable from "@/components/dashboard/PaxTable.vue"
import { DateTime } from "luxon"
import { axiosN506, axiosB } from "@/api/axiosBd"
import { saveAs } from "file-saver"
const pieColors = [
  "#29B6F6",
  "#4DD0E1",
  "#81C784",
  "#90A4AE",
  "#F06292",
  "#9575CD",
  "#3F51B5",
]
const monthColors = [
  "#FF7043",
  "#9CCC65",
  "#26C6DA",
  "#29B6F6",
  "#5C6BC0",
  "#42A5F5",
  "#26A69A",
  "#C62828",
  "#EEFF41",
  "#0D47A1",
]

export default {
  name: "MainDashboard",
  components: {
    BarChart,
    CardTotals,
    Pie,
    TableMiddle,
    PaxTable,
    CardSimple
  },
  props: {
    yearData: {
      type: Number,
      required: true,
    },
    monthData: {
      type: Number,
      required: true,
    },
    dayData: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dataYears: [],
      dialogDescargar: false,
      loading: false,
      itemsDataTable: [],
      itemsPaxTable: [],
      items: [
        { title: "Hoy" },
        { title: "Ayer" },
        { title: "Semana" },
        { title: "Mes" },
        { title: "Año" },
      ],
      textLines: {
        text: {
          color1: "primary--text",
          line1: "267,433 Sc",
          color2: "warning--text font-weight-bold",
          line2: "255,386 Tax",
        },
      },
      chartDataBars: {
        labels: [],
        datasets: [],
      },
      chartDataPie: {
        labels: [],
        datasets: [],
      },
      chartDataBarsYearbyMonth: {
        labels: [],
        datasets: [],
      },
      chartOptionsBars: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
      chartOptionsPie: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      meses: [
        { Enero: 1 },
        { Febrero: 2 },
        { Marzo: 3 },
        { Abril: 4 },
        { Mayo: 5 },
        { Junio: 6 },
        { Julio: 7 },
        { Agosto: 8 },
        { Septiembre: 9 },
        { Octubre: 10 },
        { Noviembre: 11 },
        { Diciembre: 12 },
      ],
      acumulado: 0,
      acumuladoHoy: 0,
      mes: 0,
      mesHastaLaFecha: 0,
      dia: 0,
      promPaxDia: 0,
      promPaxMes: 0,
      promVtaDia: 0,
      promVtaMes: 0,
      acumuladoPax: 0,
      acumuladoHoypax: 0,
      budgetMesHastaHoy: {},
      budgetMescompleto: {},
      budgetAcumuladoHoy: {},
      budgetAcumuladoYear: {},
      budgetPromVtaMes: 0,
      budgetPromVtaDiaPercent: 0,
      budgetPromVtaDia: 0,
      budgetPromVtaMesPercent: 0,
    }
  },
  methods: {
    openDescargar() {
      this.dialogDescargar = true
    },
    async descargarExcel(item) {
      this.loading = true
      console.log(item)
      await this.$store.dispatch("verifyTokenPy")
      const response = await axiosB({
        method: "GET",
        url: `extractor/api/506transfers/resultBymonth?year=${this.yearData}&mes=${
          Object.values(item)[0]
        }`,
        headers: {
          Authorization: `Bearer ${this.$store.state.accessTokenPy}`,
        },
        responseType: "blob",
      })
      console.log(response.headers)
      // fetch(`extractor/api/506transfers/resultBymonth?mes=${Object.values(item)[0]}`, {
      //   method: 'GET',
      //   headers: {
      //     Authorization: `Bearer ${this.$store.state.accessTokenPy}`,
      //     'Content-Type': 'application/json',
      //   }
      // })
      // .then(res => {
      //   if(!res.ok) {
      //     return res.json().then(text => { throw new Error(text.message) })
      //   } else {
      //     console.log(res)
      saveAs(response.data, response.headers["content-filename"])
      // response.blob().then(file => {
      //   saveAs(file, `${item.nombre}`)
      //   this.isLoading = false
      // })
      //   }
      // })
      // .catch(err => {
      //   this.loading = false
      //   console.log(err.response)
      // })
    },
    async getDataOnePy(obj) {
      let dataResult = null
      try {
        await this.$store.dispatch("verifyTokenPy")
        const response = await axiosB({
          method: obj.method,
          url: obj.url,
          headers: {
            Authorization: `Bearer ${this.$store.state.accessTokenPy}`,
          },
          data: obj.body,
        })
        // console.log(response.data)
        dataResult = response.data

        this.itemsDataTable = []
        let diaPaxTable = []
        let mesPaxHastaLaFechaPaxTable = []
        let mesPaxCompleto = []
        for (let item of response.data.datosByProducts) {
          item.acumulado = response.data.subfamily_sales_acumulado.find(
            (dato) => dato.subfamilia == item.order
          )["monto"]
          item.budget_year = response.data.subfamily_sales_acumulado.find(
            (dato) => dato.subfamilia == item.order
          )["budget_year"]
          this.itemsDataTable.push(item)
          diaPaxTable.push(item.pax_dia)
          mesPaxHastaLaFechaPaxTable.push(item.pax_mes_hasta_la_fecha)
          mesPaxCompleto.push(item.pax_mes_completo)
        }
        this.itemsPaxTable = [
          { order: "Dia", monto: diaPaxTable.reduce((a, b) => a + b, 0) },
          {
            order: "Hasta la fecha",
            monto: mesPaxHastaLaFechaPaxTable.reduce((a, b) => a + b, 0),
          },
          {
            order: "Mes completo",
            monto: mesPaxCompleto.reduce((a, b) => a + b, 0),
          },
        ]

        this.chartDataBars.datasets.push({
          label: "Ventas x dia del mes",
          backgroundColor: "rgba(255, 99, 132, 0.7)",
          borderColor: "rgba(255, 99, 132)",
          data: response.data.day_sales,
        })

        let datosBar2 = {
          backgroundColor: [],
          data: [],
        }
        for (let [index, item] of response.data.meses_year.entries()) {
          this.chartDataBarsYearbyMonth.labels.push(item.nombre_mes)
          datosBar2.backgroundColor.push(monthColors[index])
          datosBar2.data.push(item.monto)
        }
        datosBar2.label = "Ventas totales x Mes"
        this.chartDataBarsYearbyMonth.datasets.push(datosBar2)

        this.dia = response.data.dia
        this.mesHastaLaFecha = response.data.mes_hasta_la_fecha
        this.mes = response.data.mes_completo
        this.acumulado = response.data.acumulado
        this.acumuladoPax = response.data.acumulado_pax
        this.acumuladoHoy = response.data.acumulado_hasta_hoy
        this.acumuladoHoypax =
          this.acumuladoPax -
          mesPaxCompleto.reduce((a, b) => a + b, 0) +
          mesPaxHastaLaFechaPaxTable.reduce((a, b) => a + b, 0)
        this.itemsPaxTable.push({
          order: "Acumulado Hoy",
          monto: this.acumuladoHoypax,
        })
        this.promPaxDia = this.dia / diaPaxTable.reduce((a, b) => a + b, 0)
        this.promPaxMes =
          this.mesHastaLaFecha /
          mesPaxHastaLaFechaPaxTable.reduce((a, b) => a + b, 0)
        this.promVtaDia =
          this.acumuladoHoy /
          this.calculateDays(new Date(), new Date("2023-01-01"))
        this.promVtaMes =
          this.acumuladoHoy /
          DateTime.now().setZone("America/Costa_Rica").month

        let dataPie = []
        for (let item of response.data.subfamily_sales) {
          this.chartDataPie.labels.push(item.subfamilia)
          dataPie.push(item.monto)
        }

        this.chartDataPie.datasets.push({
          backgroundColor: pieColors,
          data: dataPie,
        })

        // BUDGET
        this.budgetMesHastaHoy = {
          percent: ((this.mesHastaLaFecha/(dataResult.budget.mes_hasta_hoy ?? 0))*100).toFixed(2),
          meta: dataResult.budget.mes_hasta_hoy ?? 0,
          dif: this.mesHastaLaFecha - (dataResult.budget.mes_hasta_hoy ?? 0)
        }
        this.budgetMescompleto = {
          percent: ((this.mes/(dataResult.budget.mes_completo ?? 0))*100).toFixed(2),
          meta: dataResult.budget.mes_completo ?? 0,
          dif: this.mes - (dataResult.budget.mes_completo ?? 0)
        }

        this.budgetAcumuladoHoy = {
          percent: ((this.acumuladoHoy/(dataResult.budget.acumulado_hoy ?? 0))*100).toFixed(2),
          meta: dataResult.budget.acumulado_hoy ?? 0,
          dif: this.acumuladoHoy - (dataResult.budget.acumulado_hoy ?? 0)
        }

        this.budgetAcumuladoYear = {
          percent: ((this.acumulado/(dataResult.budget.acumulado_year ?? 0))*100).toFixed(2),
          meta: dataResult.budget.acumulado_year ?? 0,
          dif: this.acumulado - (dataResult.budget.acumulado_year ?? 0)
        }

        this.budgetPromVtaDia = (dataResult.budget.acumulado_year ?? 0)/365
        this.budgetPromVtaDiaPercent = ((this.promVtaDia/this.budgetPromVtaDia)*100).toFixed(2)
        this.budgetPromVtaMes = (dataResult.budget.acumulado_year ?? 0)/12
        this.budgetPromVtaMesPercent = ((this.promVtaMes/this.budgetPromVtaMes)*100).toFixed(2)




      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message)
        }
        console.log(error.config)
      }

      return dataResult
    },

    async getDataOnePy2(obj) {
      let dataResult = null
      try {
        await this.$store.dispatch("verifyTokenPy")
        const response = await axiosB({
          method: obj.method,
          url: obj.url,
          headers: {
            Authorization: `Bearer ${this.$store.state.accessTokenPy}`,
          },
          data: obj.body,
        })
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    },
    calculateDays(date_1, date_2) {
      let difference = date_1.getTime() - date_2.getTime()
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
      return TotalDays
    },
    async getData(url, board) {
      await this.$store.dispatch("verifyToken")
      axiosN506({
        method: "GET",
        url: url,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
        .then((result) => {
          this[board] = result.data.data
          // console.log(result.data.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    // const year =  DateTime.now().setZone('America/Costa_Rica').year
    // const month = DateTime.now().setZone('America/Costa_Rica').month
    // const day = DateTime.now().setZone('America/Costa_Rica').day
    this.chartDataBars.labels = this.$dayLabels(this.yearData, this.monthData)
    let ventas = {
      url: `extractor/api/506transfers/dashboardQueryView?year=${this.yearData}&month=${this.monthData}&day=${this.dayData}`,
      method: "GET",
      body: {},
    }
    this.getDataOnePy(ventas)

    // let cancellaciones = {
    //   url: `extractor/api/506transfers/cancelaciones506Transfers`,
    //   method: "GET",
    //   body: {},
    // }
    // this.getDataOnePy2(cancellaciones)
    this.getData("/invoice_detail/usedYeras", 'dataYears')
  },
}
</script>

<style>
</style>